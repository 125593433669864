<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'
import { useSwichLocale } from '~/locales'

const props = defineProps<{
  styleName: 's1' | 's2'
}>()
const $options = ref<HTMLElement>()
const $overlay = ref<HTMLElement>()
const { locale, localeLabel, localeOptions } = useSwichLocale()
const open = ref(false)

function selectLang(lang: string) {
  locale.value = lang
  open.value = false
}

useEventListener(document, 'touchmove', () => {
  open.value = false
})
</script>

<template>
  <ADropdown v-model:open="open" trigger="hover" :overlayStyle="{ zIndex: 5000 }">
    <div ref="$options" class="current-lang" @click="open = true" data-test-id="olrf">
      <div v-if="props.styleName === 's1'" class="s1">
        <span> {{ localeLabel }} </span>
        <i class="i-ep:arrow-down ml-2" />
      </div>

      <div v-if="props.styleName === 's2'" class="s2">
        <i class="icon i-bi:globe-asia-australia?mask " />
      </div>
    </div>

    <template #overlay>
      <ul ref="$overlay" class="lang-options">
        <li v-for="(item, index) in localeOptions" :key="index" class="option" @click="selectLang(item.value)" data-test-id="e2tb">
          {{ item.label }}
        </li>
      </ul>
    </template>
  </ADropdown>
</template>

<style lang="scss" scoped>
.current-lang {
  cursor: pointer;

  .s1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 44px;
    background-image:
      linear-gradient(#161d36, #161d36),
      linear-gradient(to left, #00c1a5, #2a47ab);
    background-clip: padding-box, border-box;
    background-origin: border-box;
    background-size: cover;
    border: 2px solid transparent;
    border-radius: 25px;
  }

  .s2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1.5px solid #8098bf;
    border-radius: 50%;

    @media bp-lt-tabletl {
      width: 27px;
      height: 27px;
    }

    .icon {
      display: block;
      font-size: 1.1em;
      color: #e2e8f2;
    }
  }
}

.lang-options {
  width: 160px;
  padding: 0;
  overflow: hidden;
  background-color: rgb(32 44 82);
  border-radius: 10px;

  .option {
    padding: 0.8em 1.2em;
    font-size: 16px;
    color: rgb(128 152 191);
    cursor: pointer;

    &:hover {
      color: #e2e8f2;
      background-color: #2e3f73;
    }
  }
}
</style>
